@import "../../../styles/colors.scss";

.bottom {
  background: $eerie-black;
  color: $quick-silver9c9;
  text-align: center;

  .counterContainer {
    color: #58a1ce;

    .label {
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 0;
    }

    .counter {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .containerBottom {
    position: relative;
    overflow: hidden;
    border-top: 2px solid $mine-shaft393;
  }

  .flatSocialsV1 {
    margin-left: 40px;

    li {
      display: inline-block;
      position: relative;
      text-align: center;
      top: 14px;
      padding-right: 40px;
      z-index: 9999;

      &:before {
        position: absolute;
        top: -10px;
        left: -15px;
        content: "";
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $eerie-black;
        z-index: -1;
      }

      a:hover {
        color: $danube;
      }
    }
  }

  .containerBottom span a {
    color: $havelock-blue;

    &:hover {
      color: $danube;
    }
  }
}

.containerBottom .copyright {
  padding: 45px 0 27px;
}

.bottom .flatSocialsV1 li a,
.containerBottom .copyright p {
  color: $quick-silver9c9;
}
